import { Dialog } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { Modal } from "~/components/Modal";
import {
  useFirstLoginModal,
  useOnboardingModal,
} from "~/components/PersistentState";
import { useOnboardingUserInfo } from "~/components/UserOnboardingForm/useOnboardingUserInfo";
import { UserOnboardingForm } from "~/components/UserOnboardingForm/UserOnboardingForm";
import { upsertOnboardingUserInfo } from "~/lib/client/endilApiCalls";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { Body, Card, Headline } from "~/ui-library";

export type UserOnboardingModalProps = {
  isOpen?: boolean;
};

export const UserOnboardingModal: React.FC<UserOnboardingModalProps> = ({
  isOpen,
}) => {
  const [hasFinishedModal, setHasFinishedModal] = useState(false);
  const { setShowOnboardingModal, showOnboardingModal } = useOnboardingModal();
  const { setShowFirstLoginModal, showFirstLoginModal } = useFirstLoginModal();
  const { data: session, status } = useSession();
  const userId = session?.mcAccountId;
  const { data } = useOnboardingUserInfo({ userId });

  const showModal = !!(
    !hasFinishedModal &&
    status === "authenticated" &&
    showOnboardingModal &&
    getValueOrDefault(isOpen, true)
  );

  useEffect(() => {
    // showOnboardingModal will get set in the ProcessLogin component for new users
    if (!showFirstLoginModal && status === "authenticated" && userId && data) {
      // show the modal if we're missing one of the three values
      setShowOnboardingModal(
        !(data?.industry && data.salesforceOrganization && data.salesforceTitle)
      );
    }
  }, [data, setShowOnboardingModal, showFirstLoginModal, status, userId]);

  return (
    <Modal isOpen={showModal}>
      <Card className="w-full md:max-w-[550px]">
        <div className="flex justify-center border-b-1 border-gray-300 px-m py-l">
          <Dialog.Title
            as="div"
            className="place-self-center text-center text-gray-900"
          >
            <Headline level={3} as="div">
              Thanks for reading{" "}
              <div className="inline-block">Morning Consult!</div>
            </Headline>
          </Dialog.Title>
        </div>
        <div className="p-m pb-l">
          <div className="mb-l">
            <Body bold className="inline" size="small">
              Complete your profile.&nbsp;
            </Body>
            <Body className="inline text-gray-700" size="small">
              Help us customize your experience by telling us a little bit more
              about yourself.
            </Body>
          </div>
          <UserOnboardingForm
            email={session?.user.email}
            organization={data?.salesforceOrganization}
            title={data?.salesforceTitle}
            onSubmit={({ industry, organization, title }) => {
              upsertOnboardingUserInfo({
                organization,
                title,
                industry: industry.id,
              });
              setShowOnboardingModal(false);
              setShowFirstLoginModal(false);
              setHasFinishedModal(true);
            }}
          />
        </div>
      </Card>
    </Modal>
  );
};
