import { z } from "zod";

export const userOnboardingFormSchema = z.object({
  title: z.string().min(1, { message: "Title is required" }),
  organization: z.string().min(1, { message: "Organization is required" }),
  industry: z.object(
    {
      id: z.string(),
      label: z.string(),
    },
    { required_error: "Industry is required" }
  ),
});

export type OnboardingFormData = z.infer<typeof userOnboardingFormSchema>;
