import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ConditionalError } from "~/components/ConditionalError";
import {
  OnboardingFormData,
  userOnboardingFormSchema,
} from "~/components/UserOnboardingForm/userOnboardingFormSchema";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { Body, Button, Input, Link } from "~/ui-library";
import { Select, Option } from "~/ui-library/components/Select";

type FormFields = {
  organization: string | undefined;
  title: string | undefined;
  industry: { id: string | number; label: string };
};

const options = [
  "Banking",
  "Food & Bev",
  "Retail",
  "Hospitality",
  "Tech",
  "Auto/Transportation",
  "Healthcare",
  "Insurance",
  "CPG",
  "Law firm",
  "Lobbying",
  "Government",
  "Marketing Communications",
  "Agency",
  "Professional Services",
  "Consulting",
  "Other",
].map((option) => ({
  id: option,
  label: option,
}));

export type UserOnboardingFormProps = Omit<FormFields, "industry"> & {
  email: string | undefined;
  onSubmit: (formValues: OnboardingFormData) => void;
};

const useFormHook = ({ organization, title }: Omit<FormFields, "industry">) =>
  useForm<OnboardingFormData>({
    defaultValues: {
      title,
      organization,
    },
    mode: "onBlur",
    resolver: zodResolver(userOnboardingFormSchema),
    criteriaMode: "all",
  });

export const UserOnboardingForm: React.FC<UserOnboardingFormProps> = ({
  email,
  onSubmit,
  organization,
  title,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
  } = useFormHook({
    organization: getValueOrDefault(organization, ""),
    title: getValueOrDefault(title, ""),
  });

  return (
    <form
      onSubmit={handleSubmit(() => onSubmit({ ...getValues() }))}
      className="w-full"
    >
      <div className="grid gap-s">
        <div>
          <Input
            error={errors.organization?.message}
            fullWidth
            label="Organization"
            {...register("organization")}
          />
        </div>

        <div>
          <Input
            fullWidth
            label="Title"
            error={errors.title?.message}
            {...register("title")}
          />
        </div>

        <div>
          <Select
            label="Industry"
            onChange={(e) =>
              setValue("industry", e, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              })
            }
            placeholder="Select industry..."
            selected={getValueOrDefault(
              options.find((e) => getValues().industry?.id === e.id),
              null
            )}
          >
            {options.map((option, i) => (
              <Option key={i} value={option}>
                {option.label}
              </Option>
            ))}
          </Select>
          <ConditionalError
            data-testid="input-error"
            error={errors.industry?.message}
          />
        </div>

        <Button variant="primary" fullWidth type="submit">
          Submit and continue
        </Button>

        <div>
          <Body bold className="inline">
            Not <i>{email}</i>?&nbsp;
          </Body>
          <Link appearance="underline" href="/logout">
            Sign out
          </Link>
        </div>
      </div>
    </form>
  );
};
